<template>
  <b-container>
    <b-row>
      <b-col md="12">
        <article class="post-area single-blog">
          <b-row>
            <b-col md="12">
              <h2>Dashboard</h2>
            </b-col>
          </b-row>

          <b-row>
            <loading
                :active.sync="isLoading"
                :color="'#408521'"
                :is-full-page="false"
            ></loading>

            <b-col md="12" style="min-height: 1600px">

              <b-form-group class="mt-5" label="Zapisi za prikaz v vtičniku E-duri" label-for="category"
                            v-if="posts && posts.length > 0">
                <v-select
                    v-model="eDuriSelectedPosts"
                    id="selectedPosts"
                    name="selectedPosts"
                    label="title"
                    :multiple="true"
                    :options="posts"
                    :reduce="item => item.id"
                    @input="() => {
                      if(eDuriSelectedPosts.length > eDuriMaxSelectedPosts) {
                        eDuriSelectedPosts.splice(eDuriSelectedPosts.length-1, 1)
                      }
                    }">
                  <span slot="no-options">Ni zadetkov.</span>

                  <template slot="option" slot-scope="item">
                    <div class="py-2">
                      <b>{{ item.title }}</b>
                      <div>
                        <em>{{ item.summary }}</em>
                      </div>
                    </div>
                  </template>

                  <template slot="selected-option" slot-scope="item">
                    <b>{{ item.title }}</b>
                    <div>
                      <em>{{ item.summary }}</em>
                    </div>
                  </template>
                </v-select>

              </b-form-group>

              <b-button
                  variant="info"
                  @click.stop="saveSelectedPosts()"
              ><i class="fas fa-save mr-2"></i>
                <span>Shrani izbor</span
                ></b-button
              >

            </b-col>
          </b-row>
        </article>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
// @ is an alias to /src
// import firebase from "firebase";
import {db} from "../../main";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Loading from "vue-loading-overlay";
// import firebase from "firebase";
// import _ from "lodash";
// import _ from "lodash";

export default {
  name: "Dashboard",
  components: {
    vSelect,
    Loading
  },
  data() {
    return {
      posts: [],
      isLoading: false,
      configId: null,
      eDuriMaxSelectedPosts: 6,
      eDuriSelectedPosts: [],
    };
  },
  async mounted() {
    await this.retrievePosts();
    await this.retrieveSelectedPosts();
  },
  methods: {
    async retrievePosts() {
      const _this = this;

      _this.isLoading = true;

      let query = db.collection("posts");
      query = query.where("confirmed", "==", true);

      await query
          .orderBy("confirmedAt", "desc")
          .get()
          .then(function (_docs) {
            console.log(_docs);
            let posts = _docs.docs.map(doc => doc.data());
            _this.posts = posts;
            // console.log("posts", posts);
            _this.isLoading = false;
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },

    async retrieveSelectedPosts() {
      const _this = this;

      _this.isLoading = true;
      let query = db.collection("config");


      await query
          .get()
          .then(function (_docs) {
            let config = _docs.docs.map(doc => doc.data());
            _this.eDuriSelectedPosts = config[0].eDuriSelectedPosts;
            _this.eDuriMaxSelectedPosts = config[0].eDuriMaxSelectedPosts;
            _this.configId = config[0].id;
            _this.isLoading = false;
          })
          .catch(error => {
            console.log("Error getting categories:", error);
            _this.isLoading = false;
          });
    },

    async saveSelectedPosts() {
      const _this = this;

      _this.isLoading = true;

      const post = db.collection("config").doc(_this.configId);

      return post
          .update({
            eDuriSelectedPosts: _this.eDuriSelectedPosts,
          })
          .then(function () {
            console.log("config updated!");

            _this.$swal("Izbor člankov za vtičnik uspešno posodobljen 👍", "", "success");

            _this.isLoading = false;
          })
          .catch(function (error) {
            // The document probably doesn't exist.
            console.error("Error updating user: ", error);
            _this.isLoading = false;
          });

    },
  },
};
</script>

<style lang="scss">
.v-select {
  .vs__dropdown-toggle {
    background-color: #f6f4ec !important;
    border-color: #ded3c3 !important;
    border-radius: 14px !important;
    overflow: hidden !important;

    .vs__selected-options {
      padding: 0.375rem 0.75rem;
      width: 100%;
      display: block;

      .vs__selected {
        display: block !important;
        color: #6d5a3f !important;
        font-size: 1.1rem;
        font-weight: 600;
        background-color: #ffffff;
        padding: 10px !important;
      }
    }
  }

  .vs__dropdown-menu {
    display: inital !important;
    color: #6d5a3f !important;
    font-size: 1.1rem;
    font-weight: 600;
    box-shadow: 0px 40px 80px rgba(0, 0, 0, 0.5);
    border-radius: 20px;
    padding-top: 0px;
    padding-bottom: 0px;
  }

  .vs__dropdown-option {
    white-space: normal !important;
    color: #6d5a3f !important;
    font-size: 1.1rem;
    font-weight: 600;
    transition: 0.3s all ease-in;
    padding: 20px;
    //background-color: #f4f0e2;

    &.vs__dropdown-option--highlight {
      background: #e3decd;
      cursor: pointer;
    }
  }
}
</style>
